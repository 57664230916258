/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/06/2022.
 */
import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const CheckBox = ({ formHook, formKey }) => {
  const {
    register,
    formState: { errors },
  } = formHook

  const [state, setState] = useState(true)
  const toggle = () => {
    console.log(' SignUpForm > click = ', state)
    setState(!state)
  }

  const errorState = errors[formKey]
  const { t } = useTranslation()
  return (
    <>
      <div className="d-flex mt-3">
        <div className="pe-2 ">
          <div>
            <input
              className="form-check-input pointer"
              type="checkbox"
              id={'checkbox-' + formKey}
              value=""
              aria-label="..."
              {...register(formKey, {
                required: t('requiredPermission'),
              })}
              style={{ padding: '.8rem', border: '1px solid #b6b6b6' }}
            />
          </div>
        </div>
        <p className="ps-1 p-small">
          {t('termsAndPrivacy.termsAgree')}
          <a className="px-1" href="https://google.com" target="_blank" rel="noopener noreferrer">
            {t('termsAndPrivacy.termsService')}
          </a>
          {t('termsAndPrivacy.termsAnd')}{' '}
          <a href="https://studiesandme.com/privacy" className="" target="_blank" rel="noopener noreferrer">
            {t('termsAndPrivacy.termsPrivacy')}
          </a>
          {t('termsAndPrivacy.termsHealthData')}
        </p>
      </div>
      {errorState ? (
        <div className="p-small text-danger mt-1" style={{ paddingLeft: 33 }}>
          {errorState.message}
        </div>
      ) : null}
    </>
  )
}
