/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19/11/2022.
 */
import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type Props = { scrollToSignup: () => void }

const factsArr = ['1', '2', '3', '4', '5', '6']
const valueArr = ['1', '2', '3', '4', '5', '6']
const suitableArr = ['1', '2', '3', '4', '5']

export const FactsAboutTheStudy = ({ scrollToSignup }: Props) => {
  // saw a bug on a android browser where this was
  const [viewFull, setViewFull] = useState<boolean>(false)
  //const { t } = useTranslation('signup-landing-about-study')
  const { t } = useTranslation()
  const toggleListHeight = () => {
    setViewFull(!viewFull)
  }

  return (
    <div className="container-fluid pb-7" style={{ background: '#E1EBFB' }}>
      <Section headlineKey="headlineFacts" itemKey="facts." arr={factsArr}></Section>
      {/* <Section headlineKey="headlineValue" itemKey="values." arr={valueArr}></Section> */}
      {/* <Section headlineKey="headlineSuitable" itemKey="specs." arr={suitableArr}></Section> */}

      <div className="row" style={{}}>
        <div className="col-12 d-flex mt-5 justify-content-center">
          <button
            role="button"
            onClick={scrollToSignup}
            className="btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
          >
            {t('form.signupButton')}
          </button>
        </div>
      </div>

      {/* <div className="row" style={{}}>
        <div className="col-12 d-flex justify-content-center mt-4">
          {!viewFull ? (
            <button
              role="button"
              onClick={toggleListHeight}
              className="btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
            >
              {t('readFull')} IS MOBILE
            </button>
          ) : (
            <button
              role="button"
              onClick={scrollToSignup}
              className="btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
            >
              {t('signupButton')} IS MOBILE
            </button>
          )}
        </div>
      </div>*/}
    </div>
  )
}

const Section = ({ headlineKey, itemKey, arr }) => {
  const { t } = useTranslation()

  const [viewFull, setViewFull] = useState<boolean>(true)
  const toggleListHeight = () => {
    setViewFull(!viewFull)
  }
  return (
    <>
      <div className="row" style={{}}>
        <div className="col-12 mt-md-6 mt-3">
          <h4 className=" mb-5 mt-4 text-center ">{t(headlineKey)} </h4>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-md-8 col-12 mx-auto">
          <div className="row overflow-hidden position-relative" style={{ height: viewFull ? 'auto' : 220 }}>
            {!viewFull ? (
              <div
                className="position-absolute w-100 h-75 "
                style={{
                  left: 0,
                  bottom: 0,
                  background: ' linear-gradient( rgba(225,235,251,0), rgba(225,235,251,1))',
                }}
              ></div>
            ) : null}
            <div className="col-12">
              <Line></Line>
            </div>

            {arr.map((item, index) => {
              const k = itemKey + item
              const label = t(k)
              const isLast = index === arr.length - 1
              return <InfoItem key={index} item={label} isLast={isLast}></InfoItem>
            })}
          </div>
          {/*<div className="row" style={{}}>
            <div className="col-12 d-flex justify-content-center">
              {!viewFull ? (
                <button
                  role="button"
                  onClick={toggleListHeight}
                  className="btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('readFull')} IS MOBILE
                </button>
              ) : null}
            </div>
          </div>*/}
        </div>
      </div>
    </>
  )
}

const InfoItem = ({ item, isLast }) => {
  return (
    <div className="col-12  ">
      <div className="d-flex py-5 ">
        <div className="mx-3" style={{ marginTop: 12 }}>
          <div className="rounded-circle" style={{ background: 'black', width: 6, height: 6 }}></div>
        </div>
        <p className="mb-0  fw-bold">{item}</p>
      </div>
      {!isLast && <Line></Line>}
    </div>
  )
}

const Line = () => {
  return <div className="w-100 " style={{ background: 'black', height: 1, opacity: 0.4 }}></div>
}
