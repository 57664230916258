import {BASE_URL} from '../constant'
import {navigate} from 'gatsby'
import {getUtm} from '../utils/utm'

type SignUpReponse = {
	result: true
	data: {
		userName: string
		email: string
		authChallenge: string
		projectId: string
		type?: string
	}
}


type SignUpFormData = {
	email: string
	acceptedTerms: boolean
	zipcode: string // the actual input in the inpiut field
	locationId: string // something like "postcode.106581740"
	position: {
		ip: string
		country_code: string
		latitude: number
		longitude: number
	}
}

type UtmType = {
	utm_campaign?: string
	utm_content?: string
	utm_medium?: string
	utm_source?: string
	utm_study?: string
}

type DataWithGrant = SignUpFormData & {
	project: string
	grantType: string
	language: string // es or en
	utm: UtmType
}

let k = 0

export const signUpUser = (data: SignUpFormData, language) => {

	const utmObj: UtmType = getUtm()
	const p = BASE_URL + '/users/signup'

	k = k +1
	const now = new Date().getMinutes()
	console.log (" SignUpUser > now = " , now);
  const isEven = now % 2 === 1 ? true : false
	console.log (" SignUpUser > k = " , isEven);

	if (isEven) {
		navigate('/thanks')
	} else {
		navigate('/emailLogin')
	}


}
